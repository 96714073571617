$(document).ready(function() {

  /* Frontend */
  navitagionOnScroll();
  anchorGoToHash();
  formStyles();
  modalTrigger();

  $('.c-navigation a[href*="#"]:not([href="#"])').on('click', function(){
    if($('.c-navigation').hasClass('is-opened')){
      $('.c-navigation, .c-navigation__trigger, .c-navigation__bars').removeClass('is-opened');
    }
  })

  var swiper = new Swiper(".hero-swiper", {
    effect: 'fade',
    loop: true,
    fadeEffect: {
      crossFade: true
    },
    autoplay: {
      delay: 5000,
    },
  });
});
