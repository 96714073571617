$(document).on('resize', function() {
  navitagionOnScroll();
});

$(document).on('scroll', function() {
  navitagionOnScroll();
});

/* Lleva cualquier #hash de la url a un ID existente en la pagina con una trasicion suave */
function anchorGoToHash() {
  $('a[href*="#"]:not([href="#"])').click(function (e) {
    if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      if (target.length) {
        $('html, body').animate({
          scrollTop: target.offset().top
        }, 500);
        return false;
      }
    }

    if($('.c-navigation').hasClass('is-opened')){
      $('.c-navigation, .c-navigation__bars').removeClass('is-opened');
    }
  });
}

/* nav styles and actions */
function navitagionOnScroll() {
  var st = $(window).scrollTop();

  if (st > 0) {
    $('.c-navigation').addClass('is-dark');
  }  else {
    $('.c-navigation').removeClass('is-dark');
  }

  if ($('.l-hero').length > 0) {
    if (st > 0) {
      $('.c-navigation').removeClass('is-transparent');
    }  else {
      $('.c-navigation').addClass('is-transparent');
    }
  }

  if ($('.l-header').length > 0) {
    if (st > 0) {
      $('.l-header').addClass('is-small');
      $('.l-section_product').addClass('is-triggered');
      $('.l-section_product').css('padding-top', 1);
    }  else {
      $('.l-header').removeClass('is-small');
      $('.l-section_product').removeClass('is-triggered');
      $('.l-section_product').css('padding-top', 0);
    }
  }
}

/* nav behavior on trigger */
function triggerMenuNavigation(el) {
  var bars = el.find('.c-navigation__bars');

  if (bars.hasClass('is-opened')) {
    bars.removeClass('is-opened');
    $('.c-navigation').removeClass('is-opened');
    el.removeClass('is-opened');
    if ( $('.c-navigation').hasClass('is-transparent') ) {
      $('.c-navigation').removeClass('is-dark');
    }
  } else {
    bars.addClass('is-opened');
    $('.c-navigation').addClass('is-opened');
    el.addClass('is-opened');
  }
}

function prefentDefaultFunction(event) {
  event.preventDefault();
}

function formStyles() {

  // Labels states
  $('.c-form__group').each(function(){
    $(this).addClass('is-label-down');
  });

  $('.c-form__group input, .c-form__group select, .c-form__group textarea').each(function() {
    var me = $(this),
        parent = me.parent();

    if( me.val() == '' || me.val() == undefined ) {
      parent.addClass('is-label-down');
    } else {
      parent.removeClass('is-label-down');
    }
  });

  $('.c-form__group').find('input, select, textarea').on('keyup blur focus', function(e){

      // Cache our selectors
      var me = $(this),
          parent = me.parent();

      // Add or remove classes
      if (e.type == 'keyup') {
        if( me.val() == '' ) {
          parent.addClass('is-label-down');
        } else {
          parent.removeClass('is-label-down');
        }
      } else if (e.type == 'blur') {
        if( me.val() == '' ) {
          parent.addClass('is-label-down');
        }
        else {
          parent.removeClass('is-label-down');
        }
      } else if (e.type == 'focus') {
        parent.removeClass('is-label-down');
      }
  });
}

// Email validation
function valemail(email) {
  var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

function fieldValidation() {
  var isValid = false;

  $('.c-form input, .c-form select, .c-form textarea').each(function () {
    if ($.trim($(this).val()) === '' && $(this).prop('required')) {
      $(this).parent().addClass('has-errors');
    } else {
      $(this).parent().removeClass('has-errors');
    }
  });

  if (!$('.c-form .c-form__group').hasClass('has-errors')) {
    isValid = true;
  }

  return isValid;
}

function contactValidation(event) {
  event.preventDefault();

  var data = new FormData();
  var fields = ['name', 'shop-type', 'email', 'phone'];

  for (var i = 0; i < fields.length; i++) {
    data.append(fields[i], $("#" + fields[i]).val());
  }
  console.log(data);

  if ( fieldValidation() ) {
    sendData(data, "./assets/php/phpmailer/sendEmail.php");
  }

}

function modalTrigger(){
  $('.c-modal__trigger').on('click', function(){
    $('.c-modal__container').addClass('is-visible');
  })
  $('.c-modal__close').on('click', function(){
    $('.c-modal__container').removeClass('is-visible');
  })
}
