//Envia el form
function sendData(data, url) {
  var XHR = new XMLHttpRequest();

  // We define what will happen in case of error
  XHR.addEventListener('error', function (event) {
    console.log('Algo salió mal. No se enviaron los datos. (error)');
  });

  // We setup our request
  XHR.open('POST', url);

  // We just send our FormData object, HTTP headers are set automatically
  XHR.send(data);

  // We define what will happen if the data are successfully sent
  XHR.addEventListener('load', function (event) {
    // console.log(event.target.response);
    if (event.target.response === 'OK.') {
      console.log(event.target.response);
      console.log('Su mensaje fue enviado exitosamente');
      $('.c-form__group input').val('');
      formStyles();
      $('#validation').show().text('Tu mensaje se envió correctamente.');
      setTimeout(function(){
        $('#validation').hide().text('');
      }, 5000);
    } else {
      console.log(event.target.response);
      console.log('Algo salió mal. Por favor intenta otra vez. (load)');
      $('#validation').show().text('Algo salió mal. Por favor intentá otra vez.');
    }
  });
}
